import { createRouter, createWebHistory } from 'vue-router'

const routes = [

    {
        path: "/",
        name: 'AniHomeView.vue',
        component: () => import('@/views/AniHomeView.vue')
    },
    {
        path: "/project",
        name: 'AniProjectDetail.vue',
        component: () => import('@/components/AniProjectDetail/AniProjectDetail.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
